<template>
  <div id="header">
    <div class="top">
      <img src="@/assets/Home/login.png" alt="" class="logo" />
      <ul class="left">
        <li class="first" @click="toHome">
          <img src="@/assets/Home/homeIcon.png" class="homeIcon" alt="" />
          {{ $t('home.HomePage') }}
        </li>
        <li @click="toBusiness">{{ $t('home.businessScope') }}</li>
        <li @click="toNews">{{ $t('home.News') }}</li>
        <li @click="toAdvantage">{{ $t('home.Advantage') }}</li>
        <li @click="toGroup_Introduction">{{ $t('home.Introduce') }}</li>
        <li @click="toContact_us">{{ $t('home.ContactUs') }}</li>
      </ul>
      <div class="right">
        <el-input suffix-icon="el-icon-search" v-model="searchText"> </el-input>
        <Lang></Lang>
      </div>
      <img
        class="rightPe"
        @click.stop="showMenu"
        src="@/assets/PE/Home/436.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Lang from '../components/Lang.vue'

export default {
  components: {
    Lang
  },
  data () {
    return {
      searchText: '',
      show: this.$store.state.show
    }
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNewEnergy () {
      this.$router.push('/NewEnergy')
    },
    toCar () {
      this.$router.push('/Car')
    },
    toIndustrial () {
      this.$router.push('/Industrial')
    },
    toCommunication () {
      this.$router.push('/Communication')
    },
    toInstrument () {
      this.$router.push('/Instrument')
    },
    toMotor () {
      this.$router.push('/Motor')
    },
    toRobot () {
      this.$router.push('/Robot')
    },
    toElectronic () {
      this.$router.push('/Electronic')
    },
    toNews () {
      this.$router.push('/News')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    showMenu () {
      this.$store.commit('setShow', true)
      console.log(this.$store.state.show)
    }
  }
}
</script>

<style lang="less" scoped>
#header {
  width: 100%;
  height: 960px;
  background: url('../assets/Home/458.png') no-repeat;
  background-size: cover;
  position: relative;
  .top {
    width: 1400px;
    height: 80px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .logo {
      width: 95px;
      height: 27px;
      margin-right: 30px;
    }
    .left {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        color: #fff;
        list-style: none;
        display: block;
        cursor: pointer;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        .homeIcon {
          margin-right: 10px;
        }
      }
      .first {
        border-left: 1px solid #fff;
        padding-left: 21px;
        display: flex;
        align-items: center;
      }
    }
    .right {
      margin-left: 145px;
      width: 280px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/ .el-input {
        width: 206px;
        height: 36px;
        .el-input__inner {
          background-color: rgba(255, 255, 255, 0.3);
          -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
          border: 0;
          border-radius: 25px;
          color: #fff;
        }
        .el-icon-search {
          color: #fff;
        }
      }
    }
    .rightPe {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  #header {
    width: 100%;
    height: 150px !important;
    background: url('../assets/PE/Business/471.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      // display: none;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      // display: none !important;
      .logo {
        width: 64px;
        height: 18px;
        margin-left: 25px;
        margin-top: 25px;
      }
      .left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: none;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        display: none !important;
        margin-left: 145px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border: 0;
            border-radius: 25px;
            color: #fff;
          }
          .el-icon-search {
            color: #fff;
          }
        }
      }
      .rightPe {
        display: block;
        width: 21px;
        height: 16.5px;
        position: absolute;
        right: 25px;
        top: 20.5px;
      }
    }
  }
}
</style>
<style lang="less">
.genduomenu {
  width: 223px;
  background-color: #3333336a !important;
  backdrop-filter: blur(8px);
  // border-radius: 40px;
  position: absolute;
  right: 0;
  top: 0;
  .PeSearch {
    width: 188px;
    height: 30px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0.226) !important;
      color: #fff;
      border-radius: 40px;
    }
  }
  .peMenu {
    width: 100%;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      padding: 0 15px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
